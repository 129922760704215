import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import ali from '../../assets/aboutus/ali.jpg'
import randal from '../../assets/aboutus/randal.jpeg'
import prima from '../../assets/aboutus/prima.jpg'
import eric from '../../assets/aboutus/eric.jpg'
import rani from '../../assets/aboutus/Rani.jpg'


const Card2 = () => {
  return (
    <div className="block teams-block">
      <Container>
      <div className="title-holder">
        <h2>Komodo Management & Advisory Team</h2>
      </div>
        <Row className="justify-content-md-center">
          <Col md={4} className='border'>
            <div className='content'>
              <h3>Ali Sehidun</h3>
              <span className='designation'>Co-Founder</span>
            </div>
            <div className='image'>
                <Image src={ali} className="img-team"/>
                {/* <div className='overlay'>
                  <div className='socials'>
                      <ul>
                          <li><a href=""><i className="fab fa-facebook-f"></i></a></li>
                          <li><a href=""><i className="fa-brands fa-whatsapp"></i></a></li>
                      </ul>
                  </div>
                </div> */}
                </div>
                <div className='content'>
                  <p><i class="fa-solid fa-cake-candles"></i> Birth: December 31, 1981, Nampar, West Flores</p>
                  <p><i class="fa-solid fa-language"></i> Languages: Indonesia</p>                
                    <ul>
                      <li className='liststyle'>Married, 3 children.</li>
                      <li className='liststyle'>Graduate -College of Tourism Academy in Makassar City, Sulawesi.</li>
                      <li className='liststyle'>Member of the Hotel and Restaurant Association of Indonesia, the HPI Guides Association and the ASITA Travel Agents Association</li>
                      <li className='liststyle'>Secretary, Labuan Bajo Tourism Association.</li>
                    </ul>
                  </div>
            </Col>
          <Col md={{ span: 4, offset: 4 }} className='border'>
          <div className='content'>
              <h3>Randal Glaholt</h3>
              <span className='designation'>Co-Founder</span>

            </div>
            <div className='image'>
                <Image src={randal} className="img-team"/>
                {/* <div className='overlay'>
                  <div className='socials'>
                      <ul>
                          <li><a href=""><i className="fab fa-facebook-f"></i></a></li>
                          <li><a href=""><i className="fa-brands fa-whatsapp"></i></a></li>
                      </ul>
                  </div>
                </div> */}
                </div>
                <div className='content'>
                  <p><i class="fa-solid fa-cake-candles"></i> Birth: 1954, Calgary, Canada</p>
                  <p><i class="fa-solid fa-language"></i> Languages: English</p>                
                    <ul>
                      <li className='liststyle'>Graduate Degree Environmental Design (Environmental Science)</li>
                      <li className='liststyle'>30 years consulting, 10 years tourism</li>
                      <li className='liststyle'>Work and or travel in 37 countries</li>
                      <li className='liststyle'>Passionate about biodiversity conservation, ecological restoration, sustainable tourism and development, meeting new people, cross cultural experiences.</li>
                      <li className='liststyle'>Hobbies: hiking, snorkeling/diving, photography, fly fishing, racquet sports, golf, paddling, biking.</li>
                    </ul>
                  </div>
          </Col>

        </Row>

        <Row className="justify-content-md-center">
          <Col md={4} className='border'>
            <div className='content'>
              <h3>Prima Herlim</h3>
              <span className='designation'>Assistant Operations Manager</span>
            </div>
            <div className='image'>
                <Image src={prima} className="img-team"/>
                {/* <div className='overlay'>
                  <div className='socials'>
                      <ul>
                          <li><a href=""><i className="fab fa-facebook-f"></i></a></li>
                          <li><a href=""><i className="fa-brands fa-whatsapp"></i></a></li>
                      </ul>
                  </div>
                </div> */}
                </div>
                <div className='content'>
                  <p><i class="fa-solid fa-cake-candles"></i> Birth: 23 yrs old, born in Bali, 2000</p>
                  <p><i class="fa-solid fa-language"></i> Languages: Bahasa, English</p>                
                    <ul>
                      <li className='liststyle'>Education: Bachelor Degree IT</li>
                      <li className='liststyle'>3 years working in tourism</li>
                      <li className='liststyle'>Works in Bali, Timor, Sumba, Flores, North Maluku, Ternate Tidore Isands, Banda</li>
                    </ul>
                  </div>
            </Col>
          <Col md={4} className='border'>
          <div className='content'>
              <h3>Eric Hutchings</h3>
              <span className='designation'>Marketing and Trip Design Manager</span>
            </div>
            <div className='image'>
                <Image src={eric} className="img-team"/>
                {/* <div className='overlay'>
                  <div className='socials'>
                      <ul>
                          <li><a href=""><i className="fab fa-facebook-f"></i></a></li>
                          <li><a href=""><i className="fa-brands fa-whatsapp"></i></a></li>
                      </ul>
                  </div>
                </div> */}
                </div>
                <div className='content'>
                  <p><i class="fa-solid fa-cake-candles"></i> Birth: 1953, Edmonton, Canada </p>
                  <p><i class="fa-solid fa-language"></i> Languages: English</p>                
                    <ul>
                      <li className='liststyle'>married, five children</li>
                      <li className='liststyle'>Batchelor’s Degree – Biology</li>
                      <li className='liststyle'>20 year government natural resource management, aquaculture, aquaponics; 5 years environmental consulting, 10 years tourism sector.</li>
                      <li className='liststyle'>Interests: travel, history, cooking, culture, fishing.</li>

                    </ul>
                  </div>
          </Col>
          <Col md={4} className='border'>
          <div className='content'>
              <h3>Rani Setiawanti</h3>
              <span className='designation'>Coordinator & Marketing Support Assistant</span>
            </div>
            <div className='image'>
                <Image src={rani} className="img-team"/>
                {/* <div className='overlay'>
                  <div className='socials'>
                      <ul>
                          <li><a href=""><i className="fab fa-facebook-f"></i></a></li>
                          <li><a href=""><i className="fa-brands fa-whatsapp"></i></a></li>
                      </ul>
                  </div>
                </div> */}
                </div>
                <div className='content'>
                  <p><i class="fa-solid fa-cake-candles"></i> Birth: Java, 1985</p>
                  <p><i class="fa-solid fa-language"></i> Languages: Bahasa, English</p>                
                    <ul>
                      <li className='liststyle'>Education: Diplomas in Tour Travel 2015, Accounting and Secretarial Services.)</li>
                      <li className='liststyle'>Bookings and logistic coordination, travel host.</li>
                      <li className='liststyle'>Country wide, also local focus on Bandung, Yogyakarta, Bali, Lombok.</li>
                      <li className='liststyle'>Interests: Travel, nature, local food, yoga</li>

                    </ul>
                  </div>
          </Col>

        </Row>
        
      </Container>
    </div>
  )
}

export default Card2