import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Navigationbar from '../components/navbar/Navigationbar'
import Footer from '../components/section/Footer'
import img1 from '../assets/petaindo.jpg'
const Map = () => {
  return (
    <div className='py-5'>
    <header id="header">
      <Navigationbar />
    </header>
    <main>
      
        <Row>
          <Col lg={12} className='py-5'>
            <Image src={img1} className='peta'></Image>
          </Col>
        </Row>
      
    </main>
    <footer id="footer">
      <Footer />
    </footer>
  </div>
  )
}

export default Map