import Accordion from 'react-bootstrap/Accordion';
const accordion = () => {
  return (
    <Accordion defaultActiveKey="0">
    <Accordion.Item eventKey="0">
      <Accordion.Header>Where is Flores</Accordion.Header>
      <Accordion.Body>
        <p>
            Flores Island is located in Indonesia, spread over an area of 17,000 square kilometers and is part of the Lesser Sunda Islands. It is bounded by Sulawesi to the north, Sumba to the south (famous for it arts and handicrafts, such as weaving), Komodo and Sumbawa to the west and the Alor Archipelago and Lembata to the east. Flores is towards the north-west of Timor (main island for government and economic activity).
        </p>
        <p>
            Flores is a volcanic island considered to have a genuine traditional culture and spectacular natural attractions. It is rugged, mountainous (rising to 3,000 meters) and heavily wooded. Flores is also a gateway to enter the famous Komodo Dragon Islands of Rinca and Komodo. Other nearby permanently inhabited islands include: Lembata, Adonara, Solor, Palue, Nules, Sumba, Raijua, Rote, Semau, Alor and Pantar islands.
        </p>
        <p>
            Recent census shows the population of Flores to be 1.6 million people. In the 16th century, traders and missionaries from Portugal arrived in Flores. Their influence can still be recognized in the culture and language.
        </p>




      </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="1">
      <Accordion.Header>How do I get there?</Accordion.Header>
      <Accordion.Body>
        <p>
            All major airlines fly into Denpasar, Bali, or Jakarta, Java. Then a domestic flight can be taken to Labuan Bajo or Maumere. Daily flights can be taken on Indonesia Air Transport (IAT), Merpati . Sky Aviation, and Trans Nusa Airlines.

        </p>
        <p>

            Regular local ferry is also available departing from Benoa Harbour to Labuan Bajo. Alternatively, you can come through land transportation from island to island. From Bali you would have to cross through 2 islands, namely Lombok and Sumbawa.
        </p>
      </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="2">
      <Accordion.Header>What is the weather like?</Accordion.Header>
      <Accordion.Body>
        <p>
            The climate here is tropical, averaging 25C to 30C. The region has a dry and wet season that alternates each year. Generally, the wet season is from November to March and the dry season from April to October. Heaviest rainfalls are usually recorded in December, January and into February, with high humidity conditions. Even in the height of the rainy season, the rain rarely falls all day; but be prepared, torrential rains can cause postponement of land-based tours. If your excursion is in higher altitude regions, remember, it can be much cooler. Heavier sea state conditions can also occur periodically in the winter months and may result in the need for changing itineraries from one day to the next depending on destination.

        </p>

      </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="3">
      <Accordion.Header>How long or short are the days?</Accordion.Header>
      <Accordion.Body>
        <p>
            Because of its position near to the equator, Flores Island enjoys nearly equal amount of daytime and nighttime hours all year round.

        </p>
      </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="4">
      <Accordion.Header>What about Visas?</Accordion.Header>
      <Accordion.Body>
        <p>
        For most countries, Indonesia allows travelers to obtain a tourist visa upon arrival. This visa is valid for 30 days only and costs around 25 USD. This can take some time at the airport on arrival; waiting a couple of hours is not an exception, thus, it is advised to get your visa before departure, at your nearest Indonesian Embassy. 60 day tourist visa’s are also available – please check rules and regulations with the Indonesian Consulate or Embassy in your home country. On most international flights, attendants will hand out the necessary entry/departure cards and the customs declaration forms you need to fill out. Upon arrival, customs will take these forms but the departure card you will keep until you leave the country. You are allowed to bring 2 liters of wine or spirits and 200 cigarettes per person. Be aware that bringing in drugs can lead to severe penalties and even a death sentence!

        </p>
      </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="5">
      <Accordion.Header>Do Indonesians speak English?</Accordion.Header>
      <Accordion.Body>
        <p>
            In Flores many people speak a reasonable amount of English, however with Bahasa Indonesia being the main language. Several other local languages are spoken here, all belonging to the Austronesian family.

        </p>
      </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="6">
      <Accordion.Header>What religions are on Flores?</Accordion.Header>
      <Accordion.Body>
        <p>
            The religions of Flores are primarily Islamic and Catholic. There is a high degree of community harmony on Flores and people from all faiths freely mix and will attend each others ceremonies from time to time.
        </p>
      </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="7">
      <Accordion.Header>What is the currency?</Accordion.Header>
      <Accordion.Body>
        <p>
            Local currency is the Indonesian Rupiah. As of December 2009 the exchange to one USD (United States Dollar) was 9,490 IDR (Indonesian Rupiahs). Please check your bank or internet for actual rates and currency comparisons. Bank notes: 100.000, 50.000, 20.000, 10.000, 5000, 1000 rph. Coins: 500, 200, 100, 50 IDR. Plan to bring at least half of your money in cash (US dollars being the best bet). In many of the small communities credit cards are useless, and travelers’ checks might not be exchangeable.
        </p>
      </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="8">
      <Accordion.Header>What about electricity?</Accordion.Header>
      <Accordion.Body>
        <p>
            Electric current is 220 volt
        </p>
      </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="9">
      <Accordion.Header>Will my mobile phone work in Flores Island?</Accordion.Header>
      <Accordion.Body>
        <p>
        Depending on your provider you can use your own cell-phone in most inhabited areas of Indonesia, but it is likely to be expensive. If you intend to call or sms frequently, it is best to buy an Indonesian mobile number and put the SIM card in your own phone (ask your dealer whether your phone is ‘locked’ or will accept the Indonesian SIM card). Simpati seems to provide the best coverage throughout the country. A new number is inexpensive and you can purchase calling credit vouchers in most places. An alternative to this solution is using a public phone called ‘WARTEL’, which is available most places. Rural phone service might be unreliable.
        </p>
      </Accordion.Body>
    </Accordion.Item>
  </Accordion>
  )
}

export default accordion