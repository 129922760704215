import Navigationbar from '../components/navbar/Navigationbar'
import Footer from '../components/section/Footer'
import Card1 from '../components/cards/Card1'



import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import img1 from '../assets/Komodo.jpg'
import img2 from '../assets/tour/2.jpg'

const Tours = () => {
  return (
    <div>
    <header id="header">
      <Navigationbar />
    </header>
    <section id="about" className="block about-block">
        <Container fluid>
            <div className="title-holder py-5">
                <h2>Tours</h2>

            </div>
            <Row>
                <Col md={6}>
                    <Image src={img1} ></Image>
                </Col>
                <Col md={6}>
                    <p>“Whether your interests involve activity-specific day trips or extended, multi-facetted onshore and or offshore experiences, we are committed to design and deliver your perfect travel experience. As per our Social and Environmental policy, we strive to ensure all our travel experiences provide a positive benefit to our guests, employees, and the communities we work with while minimizing our footprint on the environment. . These latter benefits may involve local employment and materials sourcing, and or contributions to initiatives that improve environmental and social conditions.”</p>
                </Col>
            </Row>


            <div className="title-holder py-5">
                <h2>Pricing</h2>

            </div>
            <Row>
              <Col md={6}>
                  <ol>
                  Pricing for our custom travel experiences includes several considerations:  
                    <li>Locations and activities</li>
                    <li>Number of guests</li>
                    <li>Duration and season of travel</li>
                    <li>Services required (e.g. expedited hotel and airport transfer, specific accommodation requirements, food & beverage, transportation, guiding and concierge services, reservations, medical, security)</li>
                    <li>Site access fees</li>
                    <li>Community cultural/site fees and contributions</li>
                    <li>Level of service (i.e. ultra-luxury, luxury, mid-range, economy)</li>
                    <li>Trip logistics, complexity, safety & security considerationsasdfasd</li>
                  </ol>
                  
              </Col>
              <Col md={6}>
                  <Image src={img2} ></Image>
              </Col>
            </Row>
            <div className="title-holder py-5">
                <h2 className="py-5">Travel Considerations</h2>
            </div>
            <Row>
                <Col lg={12}>
                    <p>Indonesia is an exotic, physically, biologically and culturally diverse country that provides many opportunities for easy and truly inspiring travel. Proper planning and understanding of conditions and factors affecting travel in the amazing country will allow you to enjoy the most from your travel experience with us.  A brief summary of some of these considerations are provide below:</p>
                </Col>
            </Row>

            <Card1 />

        </Container>
    </section>
    <footer id="footer">
      <Footer />
    </footer>
  </div>
  )
}

export default Tours
