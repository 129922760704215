// import Galery from "../components/cards/Galery"

import Navigationbar from '../components/navbar/Navigationbar'
import Footer from '../components/section/Footer'
import GalleryMap from '../components/gallery/Gallery'
const Gallery = () => {
  return (
    <div>
    <header id="header">
      <Navigationbar />
    </header>
    <main className="py-5">
      
      <GalleryMap  className="py-5"/>
      
    </main>
    <footer id="footer">
      <Footer />
    </footer>
  </div>
  )
}

export default Gallery