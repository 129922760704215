
import Navigationbar from '../components/navbar/Navigationbar'
import Footer from '../components/section/Footer'
import OurTeam from '../components/cards/OurTeam'
const AboutUs = () => {
  return (
    <div>
    <header id="header">
      <Navigationbar />
    </header>
      <main>
        
        <OurTeam />
        
      </main>
      <footer id="footer">
        <Footer />
      </footer>
  </div>
  )
}

export default AboutUs