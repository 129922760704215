import React from 'react'
import './ImageSlideShow.css'
import img1 from '../../assets/humman/1.jpg'
import img2 from '../../assets/humman/2.jpg'
import img3 from '../../assets/humman/3.jpg'
import img4 from '../../assets/humman/4.jpg'
import img5 from '../../assets/humman/5.jpg'
import img6 from '../../assets/humman/6.jpg'

const ImageSlideShow = () => {
  return (
    <div className='slide-container'>
        <div className="wrapper">
            <img src={img1} alt="" />
            <img src={img2} alt="" />
            <img src={img3} alt="" />
            <img src={img4} alt="" />
            <img src={img5} alt="" />
            <img src={img6} alt="" />
            <img src={img1} alt="" />
            <img src={img2} alt="" />



            
        </div>
    </div>
  )
}

export default ImageSlideShow