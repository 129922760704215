import React, {useState} from 'react'

import Container from 'react-bootstrap/Container';
import './Gallery.css'
import {Media} from './IndexGallery'
const Gallery = () => {
  const [file, setFile] = useState(null);

  // console.log(Media)
  return (
    <section id="works" className="block works-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Gallery</h2>

        </div>

        <div>
      
      <div className="media-container">
        {
          Media.map((file, index) => (
            <div className="media" key={index}
            onClick={() => setFile(file)}>
              {
                file.type === 'image'
                ?<img src={file.url} alt='' />
                :<video src={file.url} muted/>
              }
            </div>
            
          ))
        }
      </div>
      <div className="popup-media" style={{display:file ? 'block' : 'none'}}>
      <a onClick={() =>setFile(null)} className='x'>XXX</a>
        {
          file?.type === 'video'
          ? <video src={file?.url} muted autoPlay controls />
          : <img src={file?.url} />
        }
      </div>
    </div>  


      </Container>  
    </section>

  )
}

export default Gallery