import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';



const worksData = [
    {
        id: 1,
        link: '',
        image: require('../../assets/works/gwk.jpg'),
        title: 'Lonely Path',
        subtitle: 'Web Design'
    },
    {
        id: 2,
        link: '',
        image: require('../../assets/works/klingking.jpg'),
        title: 'Lonely Path',
        subtitle: 'Web Design'
    },
    {
        id: 3,
        link: '',
        image: require('../../assets/works/lempuyang.jpg'),
        title: 'Lonely Path',
        subtitle: 'Web Design'
    },
    {
        id: 4,
        link: '',
        image: require('../../assets/works/ulundanu.jpg'),
        title: 'Lonely Path',
        subtitle: 'Web Design'
    },
    {
        id: 5,
        link: '',
        image: require('../../assets/works/bali.jpg'),
        title: 'Lonely Path',
        subtitle: 'Web Design'
    },
    {
        id: 6,
        link: '',
        image: require('../../assets/works/ulundanu.jpg'),
        title: 'Lonely Path',
        subtitle: 'Web Design'
    },
    {
        id: 7,
        link: '',
        image: require('../../assets/works/lempuyang.jpg'),
        title: 'Lonely Path',
        subtitle: 'Web Design'
    },
    {
        id: 8,
        link: '',
        image: require('../../assets/works/ulundanu.jpg'),
        title: 'Lonely Path',
        subtitle: 'Web Design'
    },
    {
        id: 9,
        link: '',
        image: require('../../assets/works/lempuyang.jpg'),
        title: 'Lonely Path',
        subtitle: 'Web Design'
    }
]



const Galery = () => {

  return (
    <section id="works" className="block works-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Gallery</h2>
          {/* <div className="subtitle">our awesome works</div> */}
        </div>
        <Row className='portfoliolist'>
          {
            worksData.map(works => {
              return (
                <Col sm={4} key={works.id}>
                  <div className='portfolio-wrapper'>
                    <a href={works.link}>
                      <Image src={works.image} className='img-porto'/>
                      <div className='label text-center'>
                        <h3>{works.title}</h3>
                        <p>{works.subtitle}</p>
                      </div>
                    </a>
                  </div>
                </Col>
              );
            })
          }
        </Row>

      </Container>  
    </section>
  )
}

export default Galery