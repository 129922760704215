import Accordion from '../components/accordion/accordion'
import Navigationbar from '../components/navbar/Navigationbar'
import Footer from '../components/section/Footer'


const FAQ = () => {
  return (
    <div className='py-5'>
    <header id="header">
      <Navigationbar />
    </header>
    <main className='py-5'>
      
    <Accordion />
      
    </main>
    <footer id="footer">
      <Footer />
    </footer>
  </div>
    
  )
}

export default FAQ