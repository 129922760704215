import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


var servicesData = [
  {
      id:1,
      icon: `fa-solid fa-hotel`,
      title: 'Accommodations',
      description: 'Indonesia offers a full spectrum of accommodations, from glamping, beach huts, and homestays, to eco-resorts, boutique hotels, 5 Star hotels, and private villas. A wide range of luxury live aboard yachts and traditional “Phinisi” style boats are available. A few key points:',
  }, 
  {
      id:2,
      icon: `fa-solid fa-plane`,
      title: 'Air Travel',
      description: 'Indonesia hosts some 230 airports. All major Indonesian islands are served by daily scheduled flights often by multiple carriers. Most international flights and certain inter island travel rely on airports in Java, Bali, Sumatra and Sulawesi. Primary hubs are:',
  },
  {
      id:3,
      icon: `fa-solid fa-sun`,
      title: 'Climate',
      description: 'Indonesia experiences diverse climatic conditions that can affect travel, activities, and overall experience. During the winter months from about November through February, cooler, windier and wetter conditions can occur in some areas. This can provide a respite from the summer heat but can reduce visibility above and below the water and render some locations more inaccessible. Portions of northern Indonesia experience more consistent, rain forest type conditions with a limited dry season, while the bulk of islands belonging to the Southern part of the archipelago (Lesser Sunda Islands) tend to have a strong seasonaility exhibiting pronounced wet and dry periods.',
  }
]





const Card1 = () => {

  return (
    <section id="services" className="block services-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Accomodation</h2>
          {/* <div className="subtitle">Lorem ipsum dolor sit amet.</div> */}
        </div>
        <Row>
          {
            servicesData.map(service => {
              return (
                <Col sm={12} md={6} lg={4} className="holder" key={service.id}>
                  <div className="icon">
                    <i className={service.icon}></i>
                  </div>
                  <h4>{service.title}</h4>
                  <p>{service.description}</p>
                </Col>
              )
            })
          }
          
        </Row>
      </Container>
    
    </section>
  )
}

export default Card1