import Navigationbar from '../components/navbar/Navigationbar'
import Footer from '../components/section/Footer'
import Blog from '../components/cards/Blog'
const News = () => {
  return (
    <div>
    <header id="header">
      <Navigationbar />
    </header>
    <main className='py-5'>
      <Blog />
    
      
    </main>
    <footer id="footer">
      <Footer />
    </footer>
  </div>
  )
}

export default News