import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';


const blogsData = [
    {
        id: 1,
        link: 'https://news.mongabay.com/2023/10/sumatran-rhino-birth-is-rare-good-news-for-species-sliding-to-extinction/',
        image: require('../../assets/blog/4.png'),
        title: 'Sumatran Rhino Birth is rare good news for species sliding to extinction',
        category: 'Tourism',
        description: 'We don’t know how many animals are born every day on our little blue planet. But given the fecundity of insects, it’s probably in the billions — and maybe in the trillions or even quadrillions. So, it’s a rare occasion when one of these births makes global news — but then there’s nothing in the world like a Sumatran rhino (Dicerorhinus sumatrensis). On Sept. 30, the Indonesian government announced the birth of a female calf at the Sumatran Rhino Sanctuary (SRS), bringing the number of captive Sumatran rhinos to 10. Sumatran rhino, the smallest of the world’s five rhino species, is arguably the world’s most endangered large land mammal (depending on the status of the saola). Populations of Sumatran rhinos have been plummeting for decades in the wild until most have winked out entirely. An estimate last year put the total number of wild Sumatran rhinos at just 34-47 animals. Only one population, in Gunung Leuser National Park at the northern tip of the island of Sumatra, may have enough rhinos for even short-term survival.'

    },
    {
        id: 2,
        link: '',
        image: require('../../assets/works/klingking.jpg'),
        title: 'Lonely Path',
        category: 'Tourism',
        description: 'We don’t know how many animals are born every day on our little blue planet. But given the fecundity of insects, it’s probably in the billions — and maybe in the trillions or even quadrillions. So, it’s a rare occasion when one of these births makes global news — but then there’s nothing in the world like a Sumatran rhino (Dicerorhinus sumatrensis). On Sept. 30, the Indonesian government announced the birth of a female calf at the Sumatran Rhino Sanctuary (SRS), bringing the number of captive Sumatran rhinos to 10. Sumatran rhino, the smallest of the world’s five rhino species, is arguably the world’s most endangered large land mammal (depending on the status of the saola). Populations of Sumatran rhinos have been plummeting for decades in the wild until most have winked out entirely. An estimate last year put the total number of wild Sumatran rhinos at just 34-47 animals. Only one population, in Gunung Leuser National Park at the northern tip of the island of Sumatra, may have enough rhinos for even short-term survival.'
    },
    {
        id: 3,
        link: '',
        image: require('../../assets/works/lempuyang.jpg'),
        title: 'Lonely Path',
        category: 'Tourism',
        description: 'We don’t know how many animals are born every day on our little blue planet. But given the fecundity of insects, it’s probably in the billions — and maybe in the trillions or even quadrillions. So, it’s a rare occasion when one of these births makes global news — but then there’s nothing in the world like a Sumatran rhino (Dicerorhinus sumatrensis). On Sept. 30, the Indonesian government announced the birth of a female calf at the Sumatran Rhino Sanctuary (SRS), bringing the number of captive Sumatran rhinos to 10. Sumatran rhino, the smallest of the world’s five rhino species, is arguably the world’s most endangered large land mammal (depending on the status of the saola). Populations of Sumatran rhinos have been plummeting for decades in the wild until most have winked out entirely. An estimate last year put the total number of wild Sumatran rhinos at just 34-47 animals. Only one population, in Gunung Leuser National Park at the northern tip of the island of Sumatra, may have enough rhinos for even short-term survival.'
    },
    {
        id: 4,
        link: '',
        image: require('../../assets/works/ulundanu.jpg'),
        title: 'Lonely Path',
        category: 'Tourism',
        description: 'We don’t know how many animals are born every day on our little blue planet. But given the fecundity of insects, it’s probably in the billions — and maybe in the trillions or even quadrillions. So, it’s a rare occasion when one of these births makes global news — but then there’s nothing in the world like a Sumatran rhino (Dicerorhinus sumatrensis). On Sept. 30, the Indonesian government announced the birth of a female calf at the Sumatran Rhino Sanctuary (SRS), bringing the number of captive Sumatran rhinos to 10. Sumatran rhino, the smallest of the world’s five rhino species, is arguably the world’s most endangered large land mammal (depending on the status of the saola). Populations of Sumatran rhinos have been plummeting for decades in the wild until most have winked out entirely. An estimate last year put the total number of wild Sumatran rhinos at just 34-47 animals. Only one population, in Gunung Leuser National Park at the northern tip of the island of Sumatra, may have enough rhinos for even short-term survival.'
    },
    {
        id: 5,
        link: '',
        image: require('../../assets/works/bali.jpg'),
        title: 'Lonely Path',
        category: 'Tourism',
        description: 'We don’t know how many animals are born every day on our little blue planet. But given the fecundity of insects, it’s probably in the billions — and maybe in the trillions or even quadrillions. So, it’s a rare occasion when one of these births makes global news — but then there’s nothing in the world like a Sumatran rhino (Dicerorhinus sumatrensis). On Sept. 30, the Indonesian government announced the birth of a female calf at the Sumatran Rhino Sanctuary (SRS), bringing the number of captive Sumatran rhinos to 10. Sumatran rhino, the smallest of the world’s five rhino species, is arguably the world’s most endangered large land mammal (depending on the status of the saola). Populations of Sumatran rhinos have been plummeting for decades in the wild until most have winked out entirely. An estimate last year put the total number of wild Sumatran rhinos at just 34-47 animals. Only one population, in Gunung Leuser National Park at the northern tip of the island of Sumatra, may have enough rhinos for even short-term survival.'
    },
    {
        id: 6,
        link: '',
        image: require('../../assets/works/ulundanu.jpg'),
        title: 'Lonely Path',
        category: 'Tourism',
        description: 'We don’t know how many animals are born every day on our little blue planet. But given the fecundity of insects, it’s probably in the billions — and maybe in the trillions or even quadrillions. So, it’s a rare occasion when one of these births makes global news — but then there’s nothing in the world like a Sumatran rhino (Dicerorhinus sumatrensis). On Sept. 30, the Indonesian government announced the birth of a female calf at the Sumatran Rhino Sanctuary (SRS), bringing the number of captive Sumatran rhinos to 10. Sumatran rhino, the smallest of the world’s five rhino species, is arguably the world’s most endangered large land mammal (depending on the status of the saola). Populations of Sumatran rhinos have been plummeting for decades in the wild until most have winked out entirely. An estimate last year put the total number of wild Sumatran rhinos at just 34-47 animals. Only one population, in Gunung Leuser National Park at the northern tip of the island of Sumatra, may have enough rhinos for even short-term survival.'
    },
    {
        id: 7,
        link: '',
        image: require('../../assets/works/lempuyang.jpg'),
        title: 'Lonely Path',
        category: 'Tourism',
        description: 'We don’t know how many animals are born every day on our little blue planet. But given the fecundity of insects, it’s probably in the billions — and maybe in the trillions or even quadrillions. So, it’s a rare occasion when one of these births makes global news — but then there’s nothing in the world like a Sumatran rhino (Dicerorhinus sumatrensis). On Sept. 30, the Indonesian government announced the birth of a female calf at the Sumatran Rhino Sanctuary (SRS), bringing the number of captive Sumatran rhinos to 10. Sumatran rhino, the smallest of the world’s five rhino species, is arguably the world’s most endangered large land mammal (depending on the status of the saola). Populations of Sumatran rhinos have been plummeting for decades in the wild until most have winked out entirely. An estimate last year put the total number of wild Sumatran rhinos at just 34-47 animals. Only one population, in Gunung Leuser National Park at the northern tip of the island of Sumatra, may have enough rhinos for even short-term survival.'
    },
    {
        id: 8,
        link: '',
        image: require('../../assets/works/ulundanu.jpg'),
        title: 'Lonely Path',
        category: 'Tourism',
        description: 'We don’t know how many animals are born every day on our little blue planet. But given the fecundity of insects, it’s probably in the billions — and maybe in the trillions or even quadrillions. So, it’s a rare occasion when one of these births makes global news — but then there’s nothing in the world like a Sumatran rhino (Dicerorhinus sumatrensis). On Sept. 30, the Indonesian government announced the birth of a female calf at the Sumatran Rhino Sanctuary (SRS), bringing the number of captive Sumatran rhinos to 10. Sumatran rhino, the smallest of the world’s five rhino species, is arguably the world’s most endangered large land mammal (depending on the status of the saola). Populations of Sumatran rhinos have been plummeting for decades in the wild until most have winked out entirely. An estimate last year put the total number of wild Sumatran rhinos at just 34-47 animals. Only one population, in Gunung Leuser National Park at the northern tip of the island of Sumatra, may have enough rhinos for even short-term survival.'
    },
    {
        id: 9,
        link: '',
        image: require('../../assets/works/lempuyang.jpg'),
        title: 'Lonely Path',
        category: 'Tourism',
        description: 'We don’t know how many animals are born every day on our little blue planet. But given the fecundity of insects, it’s probably in the billions — and maybe in the trillions or even quadrillions. So, it’s a rare occasion when one of these births makes global news — but then there’s nothing in the world like a Sumatran rhino (Dicerorhinus sumatrensis). On Sept. 30, the Indonesian government announced the birth of a female calf at the Sumatran Rhino Sanctuary (SRS), bringing the number of captive Sumatran rhinos to 10. Sumatran rhino, the smallest of the world’s five rhino species, is arguably the world’s most endangered large land mammal (depending on the status of the saola). Populations of Sumatran rhinos have been plummeting for decades in the wild until most have winked out entirely. An estimate last year put the total number of wild Sumatran rhinos at just 34-47 animals. Only one population, in Gunung Leuser National Park at the northern tip of the island of Sumatra, may have enough rhinos for even short-term survival.'
    }
]



const Blog = () => {
  return (
    <section id="works" className="block works-block">
      <Container fluid>
        <div className="title-holder bold">
          <h2>Environment and Cultural</h2>
          <h3>GOOD NEWS</h3>
        </div>
        <Row>
          {
            blogsData.map(blog => {
              return (
                <Row>
                    <Col className='p-3'>
                    <a href={blog.link}>
                        <Card>
                                {/* <Card.Header>{blog.id} {blog.title}</Card.Header> */}
                                <Card.Body>
                                <Row>
                                    <Col md={4}>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <Image src={blog.image} className='img-blog' />
                                    </div>
                                    </Col>
                                    <Col>

                                    <Card.Text>
                                      <p>{blog.category}</p>
                                      <Card.Title className='capitalize'> {blog.title}</Card.Title>
                                      <p>{blog.description}</p>
                                    </Card.Text>
                                  
                                    </Col>
                                </Row>

                                
                                </Card.Body>

                                
                            </Card>
                    </a>

                        
                    
                    </Col>
                </Row>
                // <Col sm={4} key={blog.id}>
                //   <div className='portfolio-wrapper'>
                //     <a href={blog.link}>
                //       <Image src={blog.image} className='img-porto'/>
                //       <div className='label text-center'>
                //         <h3>{blog.title}</h3>
                //         <p>{blog.subtitle}</p>
                //       </div>
                //     </a>
                //   </div>
                // </Col>
              );
            })
          }
        </Row>

      </Container>  
    </section>
  )
}

export default Blog