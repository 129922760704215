
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.min.js"
import Navigationbar from './components/navbar/Navigationbar';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


import Home from '../src/pages/Home'
import AboutUs from '../src/pages/AboutUs'

import Enquiry from '../src/pages/Enquiry'
import Gallery from '../src/pages/Gallery'
import Tour from '../src/pages/Tours'
import Map from '../src/pages/Map'
import WhatWeDo from '../src/pages/WhatWeDo'
import FAQ from '../src/pages/FAQ'
import News from '../src/pages/News'
import Destinations from "./pages/Destination"
function App() {
  return (

    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/AboutUs" element={<AboutUs />} />  
          <Route path="/Enquiry" element={<Enquiry />} />  
          <Route path="/Gallery" element={<Gallery />} />  
          <Route path="/Tour" element={<Tour />} />  
          <Route path="/Map" element={<Map />} />  
          <Route path="/WhatWeDo" element={<WhatWeDo />} />  
          <Route path="/FAQ" element={<FAQ />} />  
          <Route path="/News" element={<News />} />  
          <Route
              exact
              path="/Destinations/:id"
              element={<Destinations />}
            />
          {/* <Route
              exact
              path="/Destinations/:id"
              element={<Destinations />}
            /> */}
        </Routes>
      </Router>
       
    </div>
  );
}

export default App;
