import React from 'react'

import Navigationbar from "../components/navbar/Navigationbar"
import Hero from "../components/hero/Hero"

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import img1 from '../assets/petaindo.jpg'


import Footer from "../components/section/Footer"
import Section1 from "../components/section/Section1"

const Home = () => {
  return (
    <div>
      <header id="header">
        <Navigationbar />
      </header>
      
      <main>
        
        <Hero />
        <div className="title-holder bold py-5">
          <h2>About Indonesia</h2>
        </div>
        
        <Image src={img1} className='peta' />
        <Section1 />
      </main>
      <footer id="footer">
        <Footer />
      </footer>
    </div>
  )
}

export default Home