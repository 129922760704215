import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

const ContactUs = () => {
  return (
    <section id='contact' className='block contact-block'>
      <Container fluid>
        <div className="title-holder">
          <h2>Contact Us</h2>
          <div className="subtitle">
            get connected with us
          </div>
        </div>

        <Form className='contact-form' action="https://formspree.io/f/xnqkovda" method="POST">
          <Row>
            <Col sm={4}>

              <Form.Control type='text' name="Name" placeholder="Full Name" required/>
            </Col>
            <Col sm={4}>

              <Form.Control type='email' name="Email" placeholder="Enter Your email address" required/>
            </Col>
            <Col sm={4}>
              <Form.Control type='tel' name="Contact" placeholder="Enter Your Contact Number" required/>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Control as="textarea" name="Desc" placeholder="Enter Your Message" required/>
            </Col>
          </Row>
          <div className="btn-holder"> 
            <Button type='submit'>Submit</Button>
          </div>
        </Form>

      </Container>
      <div className="google-map">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11528739.470605694!2d109.18815058257309!3d0.4585676530300417!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2c4c07d7496404b7%3A0xe37b4de71badf485!2sIndonesia!5e0!3m2!1sen!2sid!4v1696216892778!5m2!1sen!2sid"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </section>
  )
}

export default ContactUs