import Carousel from 'react-bootstrap/Carousel';



var heroData = [
    {
        id:1,
        image: require('../../assets/hero/1.jpg'),
        title: '“Travel and change of place impart new vigor to the mind.”',
        description: '– Seneca',
    },
    {
        id:2,
        image: require('../../assets/hero/2.jpg'),
        title: '“Your true traveler finds boredom rather agreeable than painful. It is the symbol of his liberty — his excessive freedom. He accepts his boredom, when it comes, not merely philosophically, but almost with pleasure.”',
        description: '– Aldous Huxley',
    },
    {
        id:3,
        image: require('../../assets/hero/3.jpg'),
        title: '“ I never ate a durian I didn`t like"',
        description: '– Randal Glaholt',
    },
    {
        id:4,
        image: require('../../assets/hero/1.png'),
        title: '“ Travel easier with Us"',
        description: '– Zankrov',
    },
    {
        id:5,
        image: require('../../assets/hero/5.png'),
        title: '“ Lets Goo Travel with komodo traveler"',
        description: '– Prima',
    },
    {
        id:6,
        image: require('../../assets/hero/1.png'),
        title: '“ Travel easier with Us"',
        description: '– Zankrov',
    },
    {
        id:8,
        image: require('../../assets/hero/1.jpg'),
        title: '“Travel and change of place impart new vigor to the mind.”',
        description: '– Seneca',
    },

]


const Hero = () => {
  return (
    <section id="home" className="hero-block">

         <Carousel>
            {
                heroData.map(hero => {
                    return (
                        <Carousel.Item key={hero.id}>
                            <img
                                className="d-block heroimg"
                                src={hero.image}
                                alt={"Slide "+ hero.id}                 
                            />
                            
                            
                            <Carousel.Caption className='bold'>
                                <h1>{hero.title}</h1>
                                <h3>{hero.description}</h3>
                            </Carousel.Caption>
                        </Carousel.Item>
                    )
                })
            }
            
     
        </Carousel>

    </section>
  )
}

export default Hero