
import Navigationbar from '../components/navbar/Navigationbar'
import Footer from '../components/section/Footer'
const WhatWeDo = () => {
  return (
    <div>
    <header id="header">
      <Navigationbar />
    </header>
    <main>
      
        whats
      
    </main>
    <footer id="footer">
      <Footer />
    </footer>
  </div>
  )
}

export default WhatWeDo