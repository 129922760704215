import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from "react-router-dom";

import './navbar.css'
import Logo from '../../assets/Logo.png'


//bali
import bgImageBali from '../../assets/bali/bali.jpg'
import secImageBali from '../../assets/bali/barong.png';
import cardImage1 from '../../assets/bali/lempuyang.jpg'
import cardImage2 from '../../assets/bali/klingking.jpg'
import cardImage3 from '../../assets/bali/gwk.jpg'
import cardImage4 from '../../assets/bali/ulundanu.jpg'


const Navigationbar = () => {


  const destinations = [
    // { id: 0, name: "Flores-Komodo",bg: Image1}, 
    { id: 1, 
      name: "Bali",
      bg:bgImageBali , 
      secImage: secImageBali, 
      cardImage1: cardImage1, 
      cardImage2: cardImage2, 
      cardImage3: cardImage3, 
      cardImage4: cardImage4, 
      cardTitle1:'Lempuyang Temple', 
      cardTitle1:'Lempuyang Temple', 
      cardTitle2:'Klingking Beach', 
      cardTitle3:'Garuda Wisnu Kencana', 
      cardTitle4:'Ulundanu Temple' , 
      desc:"Bali, the famed Island of the Gods, with its varied landscape of hills and mountains, rugged coastlines and sandy beaches, lush rice terraces, and volcanic hillsides all providing a picturesque backdrop to its colorful, deeply spiritual, and unique culture stakes a serious claim to be paradise on earth.",
      destination: {
        list: [
          'Uluwatu Temple: Witness a mesmerizing Kecak dance performance against the backdrop of the Uluwatu Temple, perched on cliffs overlooking the Indian Ocean.',
          'Mount Batur Sunrise Trek: Embark on a predawn trek to the summit of Mount Batur and watch the sunrise over the caldera, a truly awe-inspiring experience.',
          'Ubud’s Artistic Enclave: Explore the art galleries, museums, and craft villages in Ubud, where you can witness Balinese artistry up close.',
          'Watersports: Whether a verteran or a first time water-sports enthusiast, Bali is a great place to partake in surfing, kite-boarding, SCUBA diving and Jet Skiing (try the Nusa Penida Express!).',
          'Sacred Monkey Forest Sanctuary: Interact with playful macaques in the lush Monkey Forest of Ubud while exploring ancient temple ruins.',
          'Tegallalang Rice Terraces: Marvel at the intricate and verdant rice terraces in Tegallalang, a testament to the Balinese agricultural heritage.',
          'Bali’s Underwater World: Dive or snorkel in the crystal-clear waters of Nusa Penida to encounter manta rays, vibrant coral gardens, and elusive ocean sunfish (Mola Mola).',
          'Balinese Cuisine: Delight in a culinary journey through Balinese cuisine, savoring dishes like babi guling (suckling pig) and nasi goreng (fried rice) at upscale restaurants or traditional warungs',
          'Water Temples of Bali: Explore the mystical water temples such as Tirta Empul, where you can partake in a traditional purification ritual.',
          'Luxury Beach Resorts: Enjoy the opulent comforts of Bali’s luxury beachfront resorts, offering world-class spa treatments, private villas, and gourmet dining.',
          'Bali’s Wellness Retreats: Indulge in rejuvenating spa treatments, yoga, and meditation at one of Bali’s renowned wellness retreats, set in tranquil natural surroundings.',
  
  
          
        ]
      }
    }, 
    // { id: 2, name: "Sumatra",bg: Image3},
    // { id: 3, name: "Jawa",bg: Image1},
    // { id: 4, name: "Sulawesi",bg: Image2},
    // { id: 5, name: "Lombok",bg: Image3},
    // { id: 6, name: "Papua",bg: Image1},
    // { id: 7, name: "Sumbawa",bg: Image2},
    // { id: 8, name: "Kalimantan",bg: Image3},
    // { id: 9, name: "Sumba",bg: Image1}
  
  ];

  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="/"><img src={Logo} className='logo' alt="" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">

            <Nav.Link href="/">Home</Nav.Link>
            <NavDropdown title="About Us" id="basic-nav-dropdown">
              <NavDropdown.Item href="/AboutUs">Our Team</NavDropdown.Item>
              {/* <NavDropdown.Item href="/WhatWeDo">What We Do?</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="">
                Health and safety
              </NavDropdown.Item>
              <NavDropdown.Item href="">
                Booking Condition
              </NavDropdown.Item>
              <NavDropdown.Item href="">
                Testimonial
              </NavDropdown.Item> */}
              <NavDropdown.Item href="/FAQ">
                FAQ
              </NavDropdown.Item>
            </NavDropdown>


            <NavDropdown title="Destination" id="collapsible-nav-dropdown">
                <div>
                  {destinations.map(destination => {
                    return (
                      <div key={destination.id}  >
                        <Link to={`/Destinations/${destination.id}`} state={destination}>
                          <NavDropdown.Item href="Destination/Bali" className='dropdown-item'>{destination.name}</NavDropdown.Item>
                        </Link>
                      </div>
                    )
                  })}
                </div>

              </NavDropdown>
            <Nav.Link href="/Tour">Tours</Nav.Link>
            <Nav.Link href="/Map">Map</Nav.Link>
            <Nav.Link href="/Gallery">Gallery</Nav.Link>
            <Nav.Link href="/News">Good News</Nav.Link>
            <Nav.Link href="/Enquiry">Enquiry</Nav.Link>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Navigationbar