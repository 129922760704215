

import ContactUs from '../components/section/ContactUs'
import Galery from "../components/cards/Galery"

import Navigationbar from '../components/navbar/Navigationbar'
import Footer from '../components/section/Footer'
const Enquiry = () => {
  return (
    <div>
    <header id="header">
      <Navigationbar />
    </header>
    <main className="py-5">
      
    <ContactUs />
      
    </main>
    <footer id="footer">
      <Footer />
    </footer>
  </div>
  //   <div>

  //   <header id="header py-5">
  //     <Navigationbar />
  //   </header>
  //   <main>
  //     <ContactUs />
      
      
  //   </main>
  //   <footer id="footer">
  //     <Footer />
  //   </footer>
  // </div>
  )
}

export default Enquiry