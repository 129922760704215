import React from "react";
import { useLocation, Link } from "react-router-dom";
import Navigationbar from '../components/navbar/Navigationbar'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Footer from "../components/section/Footer"
const Destinations = () => {
  const { state } = useLocation();

  // console.log("state:",state?.destinations?.id)
  console.log(state)

  // console.log("destinations:",state.destinations)
  return ( 

    <div>
    <header id="header">
      <Navigationbar />
    </header>

      
      <div className="mx-auto"
        style={{
          backgroundImage:`url(${state?.bg})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          height: '500px',

        }}
      
      >
       <div className="bgdark"><h1>{state?.name}</h1></div> 
          
      </div>



      <Container className='p-5 border-bottom'>
              <Row>
                  <Col sm={12} lg={6} className='d-flex flex-column align-items-center justify-content-center p-5'>
                      <img src={state?.secImage} className="align-items-center destinationbg" alt="" />
                  </Col>
                  <Col sm={12} lg={6} className='d-flex flex-column justify-content-center p-5'>
                      <h3>{state?.name}{" "}</h3>
                      <p>{state?.desc}{" "}</p>
                      {/* <Button variant="outline-dark rounded-0">Buy Now</Button> */}
                  </Col>
              </Row>
          </Container>
      

        {/* <div >
          <strong>Gambar: </strong><img src={state?.bg} alt="" />
          
        </div> */}
        <div className="grey">
        <Container className='p-5 border-bottom cardcenter'>
          <div className="textTitle">
            <p>Tour</p>
          </div>
          <div className="centerText">

            <p>explore and enjoy the diverse culture, nature and uniqueness of {state?.name}</p>
          </div>
          <Row sm={1} md={2} lg={4}>
            <Col className='p-5 cardcenter'>
                  <Card style={{ 
                      width: '16rem',
                      
                      height: '20rem',
                      borderRadius:'20px',
                      backgroundImage:`url(${state?.cardImage1})`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center' }}>
                      <div className="darker"></div>
                      <Card.Body>
                        <div className="cardText">
                          <p>{state?.cardTitle1}</p>
                        </div>
                      </Card.Body>
                    </Card>
            </Col>
            <Col className='p-5 cardcenter'>
                  <Card style={{ 
                      width: '16rem',
                      height: '20rem',
                      borderRadius:'20px',
                      backgroundImage:`url(${state?.cardImage2})`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center' }}>
                        <div className="darker"></div>
                      <Card.Body>
                        <div className="cardText">
                          <p>{state?.cardTitle2}</p>
                        </div>
                      </Card.Body>
                    </Card>
            </Col>
            <Col className='p-5 cardcenter'>
                  <Card style={{ 
                      width: '16rem',
                      height: '20rem',
                      borderRadius:'20px',
                      backgroundImage:`url(${state?.cardImage3})`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center' }}>
                        <div className="darker"></div>
                      <Card.Body>
                        <div className="cardText">
                          <p>{state?.cardTitle3}</p>
                        </div>
                      </Card.Body>
                    </Card>
            </Col>
            <Col className='p-5 cardcenter'>
                  <Card style={{ 
                      width: '16rem',
                      height: '20rem',
                      borderRadius:'20px',
                      backgroundImage:`url(${state?.cardImage4})`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center' }}>
                        <div className="darker"></div>
                      <Card.Body>
                        <div className="cardText">
                          <p>{state?.cardTitle4}</p>
                        </div>
                      </Card.Body>
                    </Card>
            </Col>
            
          </Row>
          <p>Some Bali Travel Highlights </p>
          <ul>
            <li>
                Uluwatu Temple: Witness a mesmerizing Kecak dance performance against the backdrop of the Uluwatu Temple, perched on cliffs overlooking the Indian Ocean.
            </li>
            <li>
                Mount Batur Sunrise Trek: Embark on a predawn trek to the summit of Mount Batur and watch the sunrise over the caldera, a truly awe-inspiring experience.
            </li>
            <li>
              Ubud’s Artistic Enclave: Explore the art galleries, museums, and craft villages in Ubud, where you can witness Balinese artistry up close.
              Watersports: Whether a verteran or a first time water-sports enthusiast, Bali is a great place to partake in surfing, kite-boarding, SCUBA diving and Jet Skiing (try the Nusa Penida Express!).

            </li>
            <li>
              Sacred Monkey Forest Sanctuary: Interact with playful macaques in the lush Monkey Forest of Ubud while exploring ancient temple ruins.

            </li>
            <li>
                Tegallalang Rice Terraces: Marvel at the intricate and verdant rice terraces in Tegallalang, a testament to the Balinese agricultural heritage.
                Bali’s Underwater World: Dive or snorkel in the crystal-clear waters of Nusa Penida to encounter manta rays, vibrant coral gardens, and elusive ocean sunfish (Mola Mola).

            </li>
            <li>
                Balinese Cuisine: Delight in a culinary journey through Balinese cuisine, savoring dishes like babi guling (suckling pig) and nasi goreng (fried rice) at upscale restaurants or traditional warungs   

            </li>
            <li>
              Shopping, Nightlife and Dining: Denpasar and general area offer a wide range of shopping, dining and entertainment experiences.
            </li>
            <li>
            Water Temples of Bali: Explore the mystical water temples such as Tirta Empul, where you can partake in a traditional purification ritual.
            </li>
            <li>
            Luxury Beach Resorts: Enjoy the opulent comforts of Bali’s luxury beachfront resorts, offering world-class spa treatments, private villas, and gourmet dining.
            </li>
            <li>
            Bali’s Wellness Retreats: Indulge in rejuvenating spa treatments, yoga, and meditation at one of Bali’s renowned wellness retreats, set in tranquil natural surroundings.
            </li>
            <li>
            Whether you are exploring ancient temples, trekking up volcanoes, or simply unwinding in luxurious beachfront villas, Bali promises an enriching and captivating escape.
            </li>
          </ul>





        
          </Container>

        </div>

        <footer id="footer">
          <Footer />
        </footer>


      
    </div>
  );
};

export default Destinations;
